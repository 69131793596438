<template>
  <div>
    <div v-if="productDetail" class="conatiner-product-eval">
      <h2 class="text-center">Valoración de producto</h2>
      <p class="text-center mx-auto" style="max-width: 1200px">
        Tu opinión es muy importante, cada vez que reseñas un producto ayudas a
        más usuarios a tomar una decisión, por eso te pedimos que NO tengas en
        cuentas aspectos como, facilidad en la compra, tiempo de entrega,
        servicio al cliente o disponibilidad, enfoca tu opinión en la calidad y
        prestaciones del producto; no olvides escribir sobre la silueta, el
        tamaño y la comodidad. Todas las opiniones son revisadas por nuestro
        equipo antes de ser publicadas.
      </p>
      <hr />
      <div class="container-valoration">
        <div class="container-product col-md-9 mx-auto mb-4 px-0 mb-4">
          <img
            :src="productDetail.referencias[0].imagenes[0]"
            class="mb-3 mb-md-0"
          />
          <div class="col-md ps-md-4">
            <p class="w-100 mb-1">{{ productDetail.titulo }}</p>
            <p class="w-100 mb-1" style="color: gray">
              SKU: {{ productDetail.producto_sku }}
            </p>
            <p class="w-100 mb-1" style="color: gray">
              Color: {{ productDetail.referencias[0].color_nombre }}
            </p>
            <p class="w-100 mb-0">
              <b
                >${{
                  new Intl.NumberFormat('de-DE').format(productDetail.precio)
                }}</b
              >
            </p>
          </div>
        </div>
        <div v-if="!sended">
          <div
            class="col-md-9 mx-auto mb-4 p-3"
            style="background-color: #f4f6f8"
          >
            <p
              class="fs-6 text-center mb-2"
              :class="validateCal ? 'text-error' : ''"
            >
              Selecciona tu calificación
            </p>
            <p class="fs-5 mb-0 text-center position-relative">
              <b-form-rating
                v-model="cal"
                no-border
                inline
                variant="danger"
                icon-empty="heart"
                icon-half="heart-half"
                icon-full="heart-fill"
                color="#F6BDC8"
                class="py-2 mb-0"
                precision="1"
                :style="
                  missingCal
                    ? `animation-name: missingCal; animation-duration: .5s;`
                    : ''
                "
              ></b-form-rating>
            </p>
          </div>
          <div v-if="!dataUser" class="col-md-9 mx-auto mb-4 px-0">
            <div class="input-simple-clover">
              <label
                :class="validateEmail ? 'invalid' : ''"
                class="label-input"
                :style="email ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
                >Correo</label
              >
              <input
                v-model="email"
                :class="validateEmail ? 'invalid-error' : ''"
                placeholder="Correo"
                type="email"
                autocomplete="email"
                @focus="validateEmail = false"
              />
              <span v-if="validateEmail" class="inavalida-input">{{
                email ? 'Correo invalido' : 'Campo requerido'
              }}</span>
            </div>
          </div>
          <div v-if="!dataUser" class="col-md-9 mx-auto mb-4 px-0">
            <div class="input-simple-clover">
              <label
                :class="validateName ? 'invalid' : ''"
                class="label-input"
                :style="name ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
                >Nombre</label
              >
              <input
                v-model="name"
                :class="validateName ? 'invalid-error' : ''"
                placeholder="Nombre"
                type="text"
                autocomplete="given-name"
                @focus="validateName = false"
              />
              <span v-if="validateName" class="inavalida-input">{{
                name ? 'Nombre invalido' : 'Campo requerido'
              }}</span>
            </div>
          </div>
          <div class="col-md-9 mx-auto mb-4 px-0">
            <div class="input-simple-clover">
              <label
                :class="validateOpinion ? 'invalid' : ''"
                class="label-input"
                :style="opinion ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
                >Escribe una frase o tú opinión sobre el producto.</label
              >
              <textarea
                v-model="opinion"
                class="pt-3"
                :class="validateOpinion ? 'invalid-error' : ''"
                placeholder="Escribe una frase o tú opinión sobre el producto."
                style="min-height: 138px"
                @focus="validateOpinion = false"
              />
              <span v-if="validateOpinion" class="inavalida-input">{{
                opinion ? 'Minimo 20 caracteres' : 'Campo requerido'
              }}</span>
            </div>
          </div>
          <div class="col-md-5 mx-auto mb-4 px-0">
            <button class="bnt-primary w-100" @click="validateForm()">
              Enviar opinión
            </button>
          </div>
        </div>
        <div v-else class="col-md-7 mx-auto mb-4 px-0">
          <h3 class="text-center">Gracias por tu opinión</h3>
          <p class="text-center">
            Hemos registrado tu comentario, estaremos evaluando el contenido.
          </p>
          <button
            class="bnt-primary mx-auto w-100"
            @click="
              $router.replace({
                name: 'ProductDetail',
                params: { url: productDetail.url.replace('product-', '') },
                query: { color: productDetail.referencias[0].color_id },
              })
            "
          >
            Volver al producto
          </button>
        </div>
      </div>
    </div>
    <skeleton-valoraciones v-else />
  </div>
</template>
<script>
import SkeletonValoraciones from '@/components/ProductValoration/SkeletonValoraciones.vue'

import { mapActions, mapState } from 'vuex'
export default {
  components: { SkeletonValoraciones },
  data() {
    return {
      cal: null,
      email: '',
      name: '',
      opinion: '',
      token: null,
      validateEmail: false,
      validateName: false,
      validateOpinion: false,
      validateCal: false,
      missingCal: false,
      sended: false,
    }
  },
  computed: {
    ...mapState('products', ['productDetail', 'errorvaloracion']),
    ...mapState('user', ['dataUser']),
    /* eslint-disable */
    validateEmailOpinion: function () {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.email)
    },
  },
  async mounted() {
    this.token = window.localStorage.getItem('accessToken')
    if (this.dataUser && window.localStorage.getItem('accessToken')) {
      this.email = this.dataUser.correo
      this.name =
        this.dataUser.persona.nombres + ' ' + this.dataUser.persona.apellidos
    }
    if (!this.productDetail && this.$route.params.url) {
      await this.getProduct({ url: 'product-' + this.$route.params.url })
    }
    // this.$gtag.pageview(this.$route.fullPath)
  },
  methods: {
    ...mapActions('products', ['getProduct', 'addValoracion']),
    async validateForm() {
      if (
        this.validateEmailOpinion &&
        this.name.length > 0 &&
        this.cal &&
        this.opinion.length > 20
      ) {
        let data = {
          puntaje: this.cal,
          comentario: this.opinion,
          sku: this.productDetail.producto_sku,
          color: this.productDetail.referencias[0].color_id,
          correo: this.email,
          nombre: this.name,
        }
        await this.addValoracion(data).then(() => {
          if (!this.errorvaloracion) {
            this.sended = true
          }
        })
      } else {
        this.validateEmail = !this.validateEmailOpinion
        this.validateName = !(this.name.length > 4)
        this.validateOpinion = !(this.opinion.length > 20)
        this.validateCal = !this.cal
        this.missingCal = !this.cal
        await setTimeout(() => {
          this.missingCal = false
        }, 1000)
      }
    },
  },
}
</script>
<style lang="scss">
.conatiner-product-eval {
  padding: 1.5rem 1rem;
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
  .container-valoration {
    margin-right: auto;
    margin-left: auto;
    max-width: 800px;
    padding: 1rem;
    margin-top: 1rem;
    .container-product {
      display: flex;
      flex-wrap: wrap;
      img {
        max-width: 140px;
        width: 100%;
        aspect-ratio: 8/9;
      }
    }
  }
}
@keyframes missingCal {
  0% {
    transform: translate(0px, 0px);
  }
  15% {
    transform: translate(-3px, 0px);
  }
  30% {
    transform: translate(3px, 0px);
  }
  45% {
    transform: translate(-3px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
</style>
